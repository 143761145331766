<template>
  <Chart :options="chartOptions" class="h-full w-full" />
</template>

<script setup>
import { computed } from 'vue';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import { baseHighchartsTheme } from './utils/ChartBaseTheming';
import histogramInit from 'highcharts/modules/histogram-bellcurve';
import { formatNumber } from '@/composeables/filters';
import cloneDeep from 'lodash/cloneDeep';
import colors from 'tailwindcss/colors';

histogramInit(Highcharts);

const props = defineProps({
  options: {
    type: Object,
    default: null
  },
  series: {
    type: Array,
    required: true
  },
  customBuckets: {
    type: Array,
    required: true
  },
  binNumber: {
    type: Number,
    default: 50
  }
});

const chartSeries = computed(() => {
  if (!props.customBuckets) {
    return props.series
      .map((e, i) => {
        return [
          {
            name: e.name,
            type: 'histogram',
            binsNumber: props.binNumber,
            baseSeries: i * 2 + 1
          },
          {
            data: e.data,
            visible: false
          }
        ];
      })
      .flat();
  } else {
    return props.series.map(series => {
      return {
        ...series,
        data: formatDataSeries(series.data, props.customBuckets)
      };
    });
  }
});

function formatDataSeries(data, buckets) {
  var bucketsCopy = cloneDeep(buckets);
  data.forEach(e => {
    bucketsCopy.every(bucket => {
      if (e < bucket.min || e > bucket.max) {
        return true;
      }
      bucket.count++;
      return false;
    });
  });
  return bucketsCopy.map(b => b.count);
}

const chartOptions = computed(() => {
  const frequency = props.options?.tooltip?.selectedTimeframe?.frequency;
  return {
    ...props.options,
    series: chartSeries.value,
    chart: {
      ...props.options.chart,
      type: props.customBuckets ? 'column' : 'histogram'
    },
    legend: {
      ...props.options.legend,
      enabled: props.series.length > 1
    },
    tooltip: {
      xDateFormat: frequency == '1day' ? '%b %d, %Y' : '%l:%M %P, %b %d, %Y',
      ...props.options.tooltip
    }
  };
});

Highcharts.setOptions(baseHighchartsTheme());
</script>
